import { Vendor } from 'etc/strapi-types'
import { Col, Grid, Row, Typography } from 'antd'
import BackgroundWrapper from 'components/background-wrapper'
import Spacer from 'components/spacer'
import VendorSlider from './vendor-slider'
import SponsorBanner from './sponsor-banner'
import styles from './hero.module.less'

const { useBreakpoint } = Grid
const { Text } = Typography

interface Props {
	vendors: Pick<Vendor, 'slug' | 'name' | 'profession' | 'logo' | 'image'>[]
}

const Hero = ({ vendors }: Props) => {
	const screen = useBreakpoint()

	return (
		<div className={styles.container}>
			<BackgroundWrapper color='#101010'>
				<Row className={styles.hero} align={screen.sm ? 'top' : 'bottom'}>
					<img
						srcSet='/images/hero-2156.jpg 2156w,
										/images/hero-1785.jpg 1785w,
										/images/hero-1350.jpg 1350w,
										/images/hero-948.jpg 948w,
										/images/hero-596.jpg 596w'
						sizes='(max-width: 596px) 596px,
									(max-width: 948px) 948px,
									(max-width: 1350px) 1350px,
									(max-width: 1785px) 1785px,
            			2156px'
						src='/images/hero-2156.jpg'
						className={styles.image}
					/>
					{!screen.sm && <div className={styles.gradient} />}
					<Col span={24}>
						<div className={styles.content}>
							<Text className={styles.title}>Der Marktplatz</Text>
							<div className={styles.handwritten}>
								<Text className={styles.handwrittenText}>der Region</Text>
							</div>
							<Text className='grey-1'>EICHSFELD – SOLLING – HARZ – WESERBERGLAND</Text>
						</div>
					</Col>
				</Row>
				<Spacer size={screen.sm ? 48 : 32} />
				<SponsorBanner />
				<VendorSlider vendors={vendors} />
			</BackgroundWrapper>
		</div>
	)
}

export default Hero
