import Link from 'next/link'
import { Article } from 'etc/strapi-types'
import dayjs from 'dayjs'
import { Col, Grid, Row, Tag, Typography } from 'antd'
import Spacer from 'components/spacer'
import styles from './article-preview.module.less'

const { Text } = Typography
const { useBreakpoint } = Grid

interface Props {
	article?: Article
	type?: 'small' | 'image' | 'image-large' | 'default'
	light?: boolean
}

const ArticlePreview = ({ article, type = 'default', light }: Props) => {
	const screen = useBreakpoint()

	return !article ? null : (
		<div className={styles.container}>
			<Link href={`/blog/${article.slug}`}>
				<a>
					{type === 'default' ? (
						<>
							<div className={styles.imageContainer}>
								<img src={article.image?.url} alt={article.title} className={styles.image} />
								<div className={styles.categories}>
									<Row gutter={[0, 4]}>
										{article.categories?.map((category, index) => (
											<Col key={index}>
												<Tag color={category?.color}>{category?.name}</Tag>
											</Col>
										))}
									</Row>
								</div>
							</div>
							<div className={styles.infos}>
								<div className={styles.title}>
									<Text className='semibold-16 grey-9'>{article.title}</Text>
								</div>
								<Row justify='space-between'>
									<Text className='regular-12 grey-8'>
										{dayjs(article.published_at).format('DD.MM.YYYY')}
									</Text>
									<Text className='regular-12 grey-7'>{article.readingTime} min Lesezeit</Text>
								</Row>
							</div>
						</>
					) : type === 'small' ? (
						<Row align='middle' gutter={[8, 0]} wrap={false}>
							<Col>
								<img src={article.image?.url} alt={article.title} className={styles.smallImage} />
							</Col>
							<Col>
								<Text className={`semibold-12 ${light ? 'grey-1' : 'grey-9'}`}>
									{article.title}
								</Text>
								<br />
								<Text className={`regular-10 ${light ? 'grey-4' : 'grey-7'}`}>
									{article.readingTime} min Lesezeit
								</Text>
								<br />
								<Text className={`regular-10 ${light ? 'grey-4' : 'grey-8'}`}>
									{dayjs(article.published_at).format('DD.MM.YYYY')}
								</Text>
							</Col>
						</Row>
					) : type === 'image' ? (
						<div className={styles.fullWidthContainer}>
							<img src={article.image?.url} alt={article.title} className={styles.fullWidthImage} />
							<div className={styles.gradient} />
							<div className={styles.fullWidthContent}>
								<Row>
									{article.categories?.map((category, index) => (
										<Tag key={index} color={category?.color}>
											{category?.name}
										</Tag>
									))}
								</Row>
								<div className={styles.title}>
									<Text className={`semibold-${screen.lg ? 24 : 20} grey-1`}>{article.title}</Text>
								</div>
								<Text className='regular-14 grey-2'>
									{dayjs(article.published_at).format('DD.MM.YYYY')} – {article.readingTime} min
									Lesezeit
								</Text>
							</div>
						</div>
					) : (
						<div className={styles.largePreviewContainer}>
							<img src={article.image?.url} alt={article.title} className={styles.largestImage} />
							{screen.sm && <div className={styles.horizontalGradient} />}
							{!screen.sm && <div className={styles.verticalGradient} />}
							<div className={styles.largeContent}>
								<Row>
									{article.categories?.map((category, index) => (
										<Tag key={index} color={category?.color}>
											{category?.name}
										</Tag>
									))}
								</Row>
								<div className={styles.title}>
									<Text className={`semibold-${screen.lg ? 24 : 20} grey-1`}>{article.title}</Text>
								</div>
								<Text className='regular-12 grey-6'>
									{dayjs(article.published_at).format('DD.MM.YYYY')} – {article.author?.name} –{' '}
									{article.readingTime} min Lesezeit
								</Text>
								{article.excerpt && (
									<div className={styles.excerpt}>
										<Text className='regular-16 grey-2'>{article.excerpt}</Text>
									</div>
								)}
								<Spacer size={8} />
								<Link href={`/blog/${article.slug}`}>
									<a>
										<Text className='grey-6 link regular-16'>Weiterlesen</Text>
									</a>
								</Link>
							</div>
						</div>
					)}
				</a>
			</Link>
		</div>
	)
}

export default ArticlePreview
