import Link from 'next/link'
import { Button, Col, Grid, Row, Typography } from 'antd'
import { Article } from 'etc/strapi-types'
import ArticlePreview from 'components/blog/article-preview'
import Slider from 'components/slider'
import Spacer from 'components/spacer'
import styles from './latest-blog-articles.module.less'

const { Text } = Typography
const { useBreakpoint } = Grid

interface Props {
	articles: Article[]
}

export const LatestBlogArticles = ({ articles }: Props) => {
	const screen = useBreakpoint()

	return (
		<div className={styles.container}>
			<Text className='regular-20 grey-8'>Unser Blog</Text>
			<Row justify='space-between' align='middle' className={styles.title}>
				<Col>
					<Text className='semibold-38'>Was gibt's Neues?</Text>
				</Col>
				{screen.sm && (
					<Col>
						<Link href='/blog'>
							<a>
								<Text className='link regular-16'>Alle Artikel</Text>
							</a>
						</Link>
					</Col>
				)}
			</Row>
			<Slider
				items={articles}
				renderItem={(article, index) => (
					<div key={index} className={styles.article}>
						<ArticlePreview article={article} type='image-large' />
					</div>
				)}
			/>
			{!screen.sm && (
				<>
					<Spacer size={24} />
					<Row justify='center'>
						<Link href='/blog'>
							<a>
								<Button type='primary' size='large'>
									Alle Artikel
								</Button>
							</a>
						</Link>
					</Row>
				</>
			)}
		</div>
	)
}
