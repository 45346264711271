import Link from 'next/link'
import { getCategories } from 'services/categories'
import { Col, Grid, Row, Typography } from 'antd'
import Spacer from 'components/spacer'
import Slider from 'components/slider'
import styles from './cards.module.less'

const { useBreakpoint } = Grid
const { Text } = Typography

const Cards = () => {
	const categories = getCategories()

	const screen = useBreakpoint()

	const items = categories
		.find(category => category.handle === 'themenwelten')
		?.items?.filter(item => item.image)

	return items ? (
		<div>
			<Spacer size={96} />
			{screen.lg ? (
				<Row gutter={[24, 24]}>
					<Col span={24} sm={12} lg={6} className={styles.col}>
						<div className={styles.info}>
							<Text className='semibold-24 primary-color'>Themenwelten</Text>
							<Spacer size={8} />
							<Text className='regular-16 grey-4'>
								Wer sich inspirieren lassen will oder gerne mal etwas Neues entdecken möchte, schaut
								in unseren Themenwelten vorbei. Hier ist garantiert für jeden Geschmack etwas dabei!
							</Text>
						</div>
					</Col>
					{items.slice(0, 7).map(item => (
						<Col span={24} sm={12} lg={6} className={styles.col} key={item.handle}>
							<Link href={`/kategorie/themenwelten/${item.handle}`}>
								<a className='hover-kill'>
									<div className={styles.imageContainer}>
										{item.image && <img src={item.image.url} className={styles.image} />}
									</div>
									<div className={styles.text}>
										<Text className={`semibold-24 grey-1`}>{item.name}</Text>
										<br />
										<Text className='regular-16 grey-1'>Jetzt ansehen</Text>
									</div>
								</a>
							</Link>
						</Col>
					))}
				</Row>
			) : (
				<Slider
					items={['first', ...items]}
					renderItem={item =>
						typeof item === 'string' ? (
							<div className={styles.colSM}>
								<div className={styles.info}>
									<Text className='semibold-24 primary-color'>Themenwelten</Text>
									<Spacer size={8} />
									<Text className='regular-16 grey-4'>
										Wer sich inspirieren lassen oder gerne mal etwas Neues entdecken möchte, schaut
										in unseren Themenwelten vorbei. Hier ist garantiert für jeden Geschmack etwas
										dabei!
									</Text>
								</div>
							</div>
						) : (
							<div className={styles.colSM}>
								<Link href={`/kategorie/themenwelten/${item.handle}`}>
									<a className='hover-kill'>
										<div className={styles.imageContainer}>
											{item.image && <img src={item.image.url} className={styles.image} />}
										</div>
										<div className={styles.text}>
											<Text className={`semibold-24 grey-1`}>{item.name}</Text>
											<br />
											<Text className='regular-16 grey-1'>Jetzt ansehen</Text>
										</div>
									</a>
								</Link>
							</div>
						)
					}
				/>
			)}
			<Spacer size={72} />
		</div>
	) : null
}

export default Cards
