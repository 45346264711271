import { shuffle } from 'services/shuffle'
import { faces } from 'etc/faces.json'
import { Typography } from 'antd'
import styles from './faces-slider.module.less'

const { Text } = Typography

interface FaceProps {
	name: string
	image: {
		url: string
		formats: {
			small: {
				url: string
			}
		}
	}
}

const Face = ({ name, image }: FaceProps) => (
	<div className={styles.item}>
		<img
			srcSet={`${image.formats.small.url} 1x, ${image.url} 2x`}
			src={image.url}
			alt={name}
			className={styles.image}
		/>
		<div className={styles.info}>
			<Text className='semibold-16 grey-1'>{name}</Text>
		</div>
	</div>
)

const FacesSlider = () => (
	<div className={styles.container}>
		<div className={styles.slider}>
			{shuffle(faces).map((face, index) => (
				<Face key={index} name={face.name} image={face.image} />
			))}
		</div>
	</div>
)

export default FacesSlider
