import { GetStaticProps, NextPage } from 'next'
import { client } from 'services/storefront'
import { getCategories, getCategory } from 'services/categories'
import { strapi } from 'services/strapi'
import { shuffle } from 'services/shuffle'
import {
	COLLECTIONS_QUERY,
	HIGHTLIGHT_PRODUCTS,
	LATEST_BLOG_ARTICLES,
	VENDOR_SLIDER
} from 'etc/queries'
import { Article, Vendor } from 'etc/strapi-types'
import { CollectionConnection, CollectionEdge, Product } from 'etc/storefront-types'
import Page from 'components/page/page'
import Banner from 'components/category/banner'
import Catalog from 'components/catalog/catalog'
import Hero from 'components/start/hero'
import Cards from 'components/start/cards'
import { Coupon } from 'components/start/coupon'
import Highlight from 'components/start/highlight'
import Selection from 'components/start/selection'
import { LatestBlogArticles } from 'components/start/latest-blog-articles'
import NewsletterSignup from 'components/page/newsletter-signup'
import FacesSlider from 'components/page/faces-slider'
import Spacer from 'components/spacer'
import styles from './index.module.less'

// @ts-ignore
type Awaited<T> = T extends PromiseLike<infer U> ? Awaited<U> : T

interface Props {
	vendors: VendorInfo[]
	latestBlogArticles: Article[]
	collections?: CollectionEdge[]
	// @ts-ignore
	highlight: Awaited<ReturnType<typeof getHighlight>>
	// @ts-ignore
	selection: Awaited<ReturnType<typeof getSelection>>
}

const Home: NextPage<Props> = ({
	vendors,
	latestBlogArticles,
	collections,
	highlight,
	selection
}) => (
	<Page>
		<Hero vendors={vendors} />
		<Cards />
		<Coupon />
		<BannerComponent edges={collections?.slice(0, 1)} />
		<NewsletterSignup size='small' light />
		<BannerComponent edges={collections?.slice(1, 2)} />
		<LatestBlogArticles articles={latestBlogArticles} />
		<BannerComponent edges={collections?.slice(2, 3)} />
		<Highlight highlight={highlight} vendors={vendors} />
		<Spacer size={64} />
		<BannerComponent edges={collections?.slice(3, 4)} />
		<Selection selection={selection} vendors={vendors} />
		<Spacer size={64} />
		<BannerComponent edges={collections?.slice(4)} />
		<FacesSlider />
	</Page>
)

export default Home

type VendorInfo = Pick<Vendor, 'slug' | 'name' | 'profession' | 'logo' | 'image'>

export const getStaticProps: GetStaticProps<Props> = async () => {
	const {
		data: { vendors }
	} = await strapi.query<{ vendors: VendorInfo[] }>({
		query: VENDOR_SLIDER
	})

	const {
		data: { articles }
	} = await strapi.query<{ articles: Article[] }>({ query: LATEST_BLOG_ARTICLES })

	const collections = await getCollections()
	const highlight = await getHighlight()
	const selection = await getSelection()

	return {
		props: {
			vendors: shuffle(vendors),
			latestBlogArticles: articles,
			collections,
			highlight,
			selection
		},
		revalidate: 1
	}
}

const HIGHLIGHT = {
	title: {
		value: 'Für alles gewappnet',
		color: '#ffffff'
	},
	content: {
		value:
			'Ausflüge in die Natur erfordern oft ein gewisses Maß an Vorbereitung. “Hab ich genug Platz im Rucksack? Kriege ich nasse Füße, wenn es anfängt zu regnen?” Alles Fragen, die durchaus ihre Berechtigung haben. Um die Reise sorgenfrei angehen zu können, solltet ihr diese ausgewählten Produkte bei eurem nächsten Trip auf jeden Fall mit einplanen!',
		color: '#fafafa'
	},
	cateogry: {
		name: 'Auf ins Abenteuer',
		handle: 'themenwelten-abenteuer',
		color: '#73d13d'
	},
	products: [],
	image: '/images/highlight-outdoor-bekleidung.jpg',
	backgroundColor: '#262626'
}

const getCollections = async () => {
	const categories = getCategories()

	let handles: string[] = []

	for (let category of categories) {
		if (category.items) {
			for (let item of category.items) {
				if (item.banner) {
					handles.push(item.handle)
				}
			}
		}
	}

	for (let i = handles.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1))
		;[handles[i], handles[j]] = [handles[j], handles[i]]
	}
	handles.splice(7)

	const query = handles.reduce((a, c) => `${a}${a.length ? ' OR ' : ''}${c}`, '')

	const { data } = await client.query<{ collections?: CollectionConnection }>({
		query: COLLECTIONS_QUERY,
		variables: { query }
	})

	const collections = data.collections?.edges.filter(edge => {
		if (edge.node.products.edges.length < 3) return false
		return true
	})

	return collections
}

const getHighlight = async () => {
	const { data } = await client.query<{
		first: Product | null
		second: Product | null
		third: Product | null
	}>({
		query: HIGHTLIGHT_PRODUCTS,
		variables: {
			first: 'fhb-outdoorhose-extrem-robust',
			second: 'eiko-erbstuck-gurtel-aus-naturleder-vollrindleder',
			third: 'camping-kochbuch-uber-100-leckere-rezepte-fur-unterwegs'
		}
	})

	const highlight = {
		...HIGHLIGHT,
		products: Object.values(data)
	}

	return highlight
}

const getSelection = async () => {
	const { data } = await client.query<{
		first: Product | null
		second: Product | null
		third: Product | null
	}>({
		query: HIGHTLIGHT_PRODUCTS,
		variables: {
			first: 'eichsfelder-stracke',
			second: 'fieldfare-diemel-dry-gin-0-5-l',
			third: 'pfanne-gusseisen'
		}
	})

	const selection = {
		title: 'Unsere Produkte des Monats',
		subtitle: 'Bestseller',
		products: Object.values(data)
	}

	return selection
}

interface BannerComponentProps {
	edges?: CollectionEdge[]
}

const BannerComponent = ({ edges }: BannerComponentProps) => {
	return (
		<div>
			{edges?.map(edge => {
				if (!edge.node.products.edges.length) return null

				const category = getCategory(edge.node.handle)
				if (!category?.banner) return null

				return (
					<div key={edge.node.handle}>
						<Banner handle={edge.node.handle} />
						{edge.node.products.edges.length > 0 && (
							<Catalog
								products={edge.node.products}
								moreHref={`/kategorie/${edge.node.handle}`}
								horizontal
								className={styles.products}
							/>
						)}
					</div>
				)
			})}
		</div>
	)
}
