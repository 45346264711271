import { Product } from 'etc/storefront-types'
import { Vendor } from 'etc/strapi-types'
import { Col, Grid, Row, Typography } from 'antd'
import BackgroundWrapper from 'components/background-wrapper'
import ProductPreview from 'components/catalog/product-preview'
import Catalog from 'components/catalog/catalog'
import Spacer from 'components/spacer'
import styles from './selection.module.less'

const { useBreakpoint } = Grid
const { Text } = Typography

interface Props {
	selection: {
		title: string
		subtitle: string
		products: Array<Product | null>
	}
	vendors: Pick<Vendor, 'slug' | 'name'>[]
}

const Selection = ({ selection, vendors }: Props) => {
	const screen = useBreakpoint()

	return (
		<BackgroundWrapper color='#262626'>
			<Spacer size={screen.md ? 128 : 64} />
			<Text className='semibold-20 grey-7'>{selection.subtitle}</Text>
			<div className={styles.title}>
				<Text className='semibold-38 grey-1'>{selection.title}</Text>
			</div>
			{screen.md ? (
				<Row justify='space-between' className={styles.products}>
					{selection.products.map(
						product =>
							product && (
								<Col key={product.handle}>
									<ProductPreview product={product} vendors={vendors} size='large' light />
								</Col>
							)
					)}
				</Row>
			) : (
				<>
					<Spacer size={48} />
					<Catalog
						products={{
							// @ts-ignore
							edges: selection.products
								.filter(product => product !== null)
								.map(product => ({ node: product, cursor: '' })),
							pageInfo: { hasNextPage: false, hasPreviousPage: false }
						}}
						light
						horizontal
					/>
				</>
			)}
			<Spacer size={screen.md ? 128 : 64} />
		</BackgroundWrapper>
	)
}

export default Selection
