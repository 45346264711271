import { Grid } from 'antd'
import styles from './sponsor-banner.module.less'

const { useBreakpoint } = Grid

const images = [
	'/images/home-banner-1.jpg',
	'/images/home-banner-logo.jpg',
	'/images/home-banner-2.jpg',
	'/images/home-banner-logo.jpg',
	'/images/home-banner-3.jpg',
	'/images/home-banner-logo.jpg',
	'/images/home-banner-4.jpg',
	'/images/home-banner-logo.jpg'
]

const SponsorBanner = () => {
	const screen = useBreakpoint()
	return (
		<div className={styles.bannerRow}>
			<div className={styles.sponsorText}>
				<span className={`regular-${screen.sm ? 24 : 20} grey-1`}>Handgefertigt</span>
			</div>
			<div className={styles.sponsorLogos}>
				<div className={styles.carousel}>
					{[...images, ...images, ...images].map((src, index) => (
						<div key={index}>
							<img src={src} className={styles.image} />
						</div>
					))}
				</div>
			</div>
		</div>
	)
}

export default SponsorBanner
