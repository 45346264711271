import Link from 'next/link'
import { Vendor } from 'etc/strapi-types'
import { Grid, Typography } from 'antd'
import Slider from 'components/slider'
import styles from './vendor-slider.module.less'

const { useBreakpoint } = Grid
const { Text } = Typography

type VendorInfo = Pick<Vendor, 'slug' | 'name' | 'profession' | 'logo' | 'image'>

interface Props {
	vendors: VendorInfo[]
}

const VendorSlider = ({ vendors }: Props) => {
	const screen = useBreakpoint()

	return (
		<div className={styles.container}>
			<div className={styles.title}>
				<Text className={`semibold-${screen.sm ? 38 : 30} grey-1`}>Das ist Edomo</Text>
			</div>
			<Slider
				items={vendors ?? []}
				renderItem={({ slug, ...vendor }: VendorInfo) => (
					<div className={styles.vendor}>
						<Link href={`/${slug}`}>
							<a className='hover-kill'>
								<img src={vendor.image?.url} width={250} height={325} className={styles.image} />
								<div className={styles.gradient} />
								<div className={styles.logoContainer}>
									<img
										src={vendor.logo?.full?.url}
										width={80}
										height={35}
										className={styles.logo}
									/>
								</div>
								<div className={styles.info}>
									{vendor.profession && (
										<Text className='semibold-12 grey-7'>{vendor.profession}</Text>
									)}
									<Text className='semibold-16 grey-5'>{vendor.name}</Text>
								</div>
							</a>
						</Link>
					</div>
				)}
				light
			/>
		</div>
	)
}

export default VendorSlider
