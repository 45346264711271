import Image from 'next/image'
import { Button, Col, Row, Typography } from 'antd'
import BackgroundWrapper from 'components/background-wrapper'
import couponImg from '../../public/images/coupon.jpg'
import styles from './coupon.module.less'
import Link from 'next/link'

const { Text } = Typography

export const Coupon = () => (
	<div className={styles.container}>
		<BackgroundWrapper color='#f5f5f5'>
			<Row align='middle' gutter={[20, 20]} className={styles.content}>
				<Col span={24} sm={14}>
					<Image src={couponImg} className={styles.image} height={450} objectFit='contain' />
				</Col>
				<Col span={24} sm={10}>
					<div className={styles.title}>
						<Text className='semibold-30'>Verschenke Schätze deiner Region</Text>
					</div>
					<div className={styles.text}>
						<Text>
							Ob zum Geburtstag, Jahrestag, Hochzeitstag oder oder oder …
							<br />
							Mit diesem Gutschein verschenkst du ein Stück Region an deine Liebsten. Hier findet
							jeder seine Lieblingsprodukte im Handumdrehen.
						</Text>
					</div>
					<Link href='/produkt/gutschein' passHref>
						<a>
							<Button type='primary' size='large'>
								Mehr erfahren
							</Button>
						</a>
					</Link>
				</Col>
			</Row>
		</BackgroundWrapper>
	</div>
)
