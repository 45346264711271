import Link from 'next/link'
import { Product } from 'etc/storefront-types'
import { Vendor } from 'etc/strapi-types'
import { Col, Grid, Row, Typography } from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons'
import BackgroundWrapper from 'components/background-wrapper'
import ProductPreview from 'components/catalog/product-preview'
import Catalog from 'components/catalog/catalog'
import styles from './highlight.module.less'

const { useBreakpoint } = Grid
const { Text } = Typography

interface Props {
	highlight: {
		title: {
			value: string
			color: string
		}
		content: {
			value: string
			color: string
		}
		cateogry: {
			name: string
			color: string
			handle: string
		}
		products: Array<Product | null>
		image: string
		backgroundColor: string
		light?: boolean
	}
	vendors: Pick<Vendor, 'slug' | 'name'>[]
}

const Highlight = ({ highlight, vendors }: Props) => {
	const { title, content, cateogry, products, image, backgroundColor, light } = highlight

	const screen = useBreakpoint()

	return (
		<div>
			{!screen.sm && <img src={image} className={styles.imageXS} />}
			<BackgroundWrapper color={backgroundColor}>
				<div
					className={styles.container}
					style={{
						background: screen.sm
							? `${
									light
										? 'linear-gradient(90deg, #f5f5f5 20%, rgba(245, 245, 245, 0) 59%, rgba(245, 245, 245, 0) 74%, #f5f5f5 100%)'
										: `linear-gradient(90deg, #262626 ${
												screen.lg ? 20 : 42
										  }%, rgba(38, 38, 38, 0) ${
												screen.lg ? 59 : 67
										  }%, rgba(38, 38, 38, 0) 74%, #262626 100%), center / cover url(${image})`
							  }`
							: undefined
					}}
				>
					<Row align='middle'>
						<Col span={24} sm={16} md={13} lg={9}>
							<Text className='semibold-20' style={{ color: cateogry.color }}>
								{cateogry.name}
							</Text>
							<div className={styles.title}>
								<Text className='semibold-38' style={{ color: title.color }}>
									{title.value}
								</Text>
							</div>
							<Text className='regular-16' style={{ color: content.color }}>
								{content.value}
							</Text>
							<div className={styles.link}>
								<Link href={`/kategorie/${cateogry.handle}`}>
									<a>
										<Text className='semibold-16' style={{ color: light ? '#262626' : '#ffffff' }}>
											Alle ansehen <ArrowRightOutlined />
										</Text>
									</a>
								</Link>
							</div>
						</Col>
					</Row>
					{screen.sm ? (
						<Row>
							{products.map(
								product =>
									product && (
										<Col key={product.handle} className={styles.preview}>
											<ProductPreview product={product} vendors={vendors} size='small' light />
										</Col>
									)
							)}
						</Row>
					) : (
						<Catalog
							products={{
								// @ts-ignore
								edges: products
									.filter(product => product !== null)
									.map(product => ({ node: product, cursor: '' })),
								pageInfo: { hasNextPage: false, hasPreviousPage: false }
							}}
							light
							horizontal
						/>
					)}
				</div>
			</BackgroundWrapper>
		</div>
	)
}

export default Highlight
