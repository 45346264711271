import Link from 'next/link'
import { getCategory } from 'services/categories'
import { Col, Grid, Row, Typography } from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons'
import BackgroundWrapper from 'components/background-wrapper'
import styles from './banner.module.less'

const { useBreakpoint } = Grid
const { Text } = Typography

interface Props {
	handle: string
	variant?: 'small' | 'default'
}

const Banner = ({ handle, variant = 'default' }: Props) => {
	const screen = useBreakpoint()

	const category = getCategory(handle)

	if (!category?.banner) return null

	const isThemenwelten = handle.includes('themenwelten')

	const { title, content, image, overlay, light, accentColor, backgroundColor, icon } =
		category.banner

	return (
		<BackgroundWrapper color={backgroundColor} className={styles.wrapper}>
			<div style={{ position: 'relative' }}>
				<Row className={styles.imageContainer}>
					<Col flex={1}>
						<img
							src={image.url}
							className={`${styles.image} ${variant === 'small' && styles.imageSmall}`}
						/>
						{screen.sm && (
							<>
								<div className={styles.overlay} />
								<div className={`${styles.fades} ${light ? styles.light : styles.dark}`} />
							</>
						)}
						{variant !== 'small' && overlay && (
							<img src={overlay.url} className={`${styles.image} ${styles.overlayImage}`} />
						)}
					</Col>
				</Row>
				<Row
					className={`${styles.container} ${variant === 'small' && styles.small}`}
					align='middle'
				>
					<Col sm={16} md={14} lg={12} xl={isThemenwelten ? 10 : 8}>
						{isThemenwelten ? (
							<Row gutter={12} align='middle' className={styles.themenwelten}>
								<Col>
									<div className={styles.icon} style={{ backgroundColor: accentColor }}>
										<img src={icon?.url} width={39} height={39} />
									</div>
								</Col>
								<Col>
									<Text className='semibold-30 grey-1'>{title}</Text>
									<br />
									<Text className='semibold-20 grey-7'>Themenwelt</Text>
								</Col>
							</Row>
						) : (
							<>
								{title && (
									<Text
										className={`semibold-${screen.md ? 20 : 16}`}
										style={{ color: accentColor }}
									>
										{category.name}
									</Text>
								)}
								<div className={styles.title}>
									<Text
										className={`semibold-${screen.md ? 38 : 30}`}
										style={{ color: light ? '#010101' : '#fff' }}
									>
										{title ?? category.name}
									</Text>
								</div>
							</>
						)}
						{variant === 'default' && (
							<div>
								<Text className='regular-16' style={{ color: light ? '#010101' : '#e8e8e8' }}>
									{content}
								</Text>
								<div className={styles.link}>
									<Link href={`/kategorie/${handle}`}>
										<a>
											<Text
												className='semibold-16'
												style={{ color: light ? '#010101' : '#fafafa' }}
											>
												Jetzt ansehen <ArrowRightOutlined />
											</Text>
										</a>
									</Link>
								</div>
							</div>
						)}
					</Col>
				</Row>
			</div>
		</BackgroundWrapper>
	)
}

export default Banner
